import { FeatureToggle } from '@vakantiesnl/services/src/hooks/rest/feature-toggle';
import { StoreApi, createStore } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';

import { addBrandPrefix } from '../brandPrefix';

export type ApplicationState = {
	isBot: boolean;
	features: FeatureToggle;
	staticCanonicalOrigin: string;
	dynamicOrigin: string;
	asPath: string;
	userId: string;

	/** Actions */
	setUserId: (id: string) => void;
};

export type ApplicationStore = StoreApi<ApplicationState>;

export const APP_STATE_STORAGE_NAME = addBrandPrefix('applicationState');

export function createApplicationStore(initialState: Partial<ApplicationState>): ApplicationStore {
	return createStore<ApplicationState>()(
		persist(
			devtools(
				(set) => ({
					isBot: initialState.isBot || false,
					features: initialState.features || {},
					/**
					 * Set the canonical origin for the page, which is static.
					 * This is determined based on the locale to ensure correct routing for SSG (Static Site Generation) pages.
					 * In zoover if no matching domain is found for the locale, it defaults to 'https://www.zoover.nl'.
					 */
					staticCanonicalOrigin: initialState.staticCanonicalOrigin || '',
					/**
					 * Get the origin of the current request, which reflects the actual site the user is on.
					 * This can vary and might be a localhost during development, a specific URL for acceptance builds,
					 * or production domains like zoover.nl or zoover.be. and it has the fallback to window.location.origin
					 * for the static pages.
					 */
					dynamicOrigin:
						initialState.dynamicOrigin || (typeof window !== 'undefined' ? window.location.origin : ''),
					asPath: initialState.asPath || '',
					userId: initialState.userId || '',

					/** Actions */
					setUserId(userId): void {
						set((state) => ({ ...state, userId }), undefined, 'setUserId');
					},
				}),
				{
					name: addBrandPrefix('ApplicationStore'),
				},
			),
			{
				// The features are stored in sessionStorage, so they can be easily retrieved in the GTM function
				name: APP_STATE_STORAGE_NAME,
				storage: createJSONStorage(() => sessionStorage),
				partialize: (state) => ({
					userId: state.userId,
					features: state.features,
				}),
				// Always use the latest version (currentState), to make sure the
				// features in sessionStorage are up to date
				merge: (_persistedState, currentState) => currentState,
			},
		),
	);
}
