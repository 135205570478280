import { useContext, createContext, FC, PropsWithChildren } from 'react';

import { MicroCopy } from '@vakantiesnl/types';

import microCopiesEn from '@MicroCopiesEN';
import microCopiesNl from '@MicroCopiesNL';

export enum Locale {
	en = 'en',
	'nl-NL' = 'nl-NL',
}

export const useMicroCopyContext = (): MicroCopy => {
	return useContext(MicroCopyContext);
};

const MicroCopyContext = createContext<MicroCopy>({} || microCopiesNl);

type Props = {
	locale?: string;
};

export const MicroCopyProvider: FC<PropsWithChildren<Props>> = ({ children, locale = Locale['nl-NL'] }) => {
	const microCopyData = locale === Locale['en'] ? microCopiesEn : microCopiesNl;

	return <MicroCopyContext.Provider value={microCopyData}>{children}</MicroCopyContext.Provider>;
};

// NB: Only use this when you need to access microcopies on the server
export function getMicroCopies(locale = Locale['nl-NL']): MicroCopy {
	return locale === Locale['en'] ? microCopiesEn : microCopiesNl;
}
